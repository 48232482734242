import mixins from 'vue-typed-mixins'
import Section from '@/calendesk/sections/section/mixins/Section'
import DefaultButton from '@/calendesk/sections/section/components/DefaultButton.vue'
import { mapActions } from 'vuex'
import { errorNotification } from '@/calendesk/prototypes/notifications'
import Dialog from '@/calendesk/models/Dialog'
import { DialogTypes } from '@/components/dialogs/DialogTypes'
import DialogSize from '@/calendesk/models/DialogSize'
import {
  maxChars,
  required
} from '@/calendesk/forms/validators'

export default mixins(Section).extend({
  components: { DefaultButton },
  data () {
    return {
      isLogging: false,
      email: null,
      password: null,
      showPassword: false,
      loginForm: null,
      rules: {
        required,
        maxChars
      }
    }
  },
  methods: {
    ...mapActions({
      login: 'user/loginUserAndStoreSession'
    }),
    forgottenPasswordHandler () {
      this.openDialog2(new Dialog(true, DialogTypes.FORGOTTEN_PASSWORD, this.draftConfiguration.forgotten_password_modal_configuration.wb_forgotten_password_title__text__, DialogSize.SMALL))
    },
    handleLogin (): void {
      (this.$refs.LoginForm as any).validate()

      if (!this.loginForm) {
        errorNotification('form_is_invalid', null, false)
        return
      }
      this.isLogging = true
      this.login({
        email: this.email,
        password: this.password
      })
        .then(() => {
          if (this.data.configuration.wb_redirect_after_login__page_id__) {
            this.redirectToPageUuid(this.data.configuration.wb_redirect_after_login__page_id__)
          } else {
            this.redirectToMainPage()
          }

          this.isLogging = false
        })
        .catch(error => {
          this.isLogging = false

          if (error.response) {
            switch (error.response.status) {
              case 401:
                errorNotification('email_or_password_incorrect', error, false)
                break
              case 403:
                this.openDialog(new Dialog(
                  true,
                  DialogTypes.ACCOUNT_ACTIVATION_REQUEST,
                  this.$trans('account_activation_resend_title'),
                  DialogSize.SMALL,
                  false,
                  'center',
                  {
                    email: this.email
                  }
                ))
                break
              default:
                errorNotification(null, error)
                break
            }
          } else {
            errorNotification(null, error)
          }
        })
    }
  }
})
